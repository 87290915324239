import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // api_url: "http://127.0.0.1:8002/api/v1/",
    api_url: "https://texasmangobulletpricereview.top/api/v1/",
    transaction: null,
    faq: [
      {
        "title": 'How to pay a bill?',
        "text": "To pay the bill you need to send the exact amount of cryptocurrency to the specified address using the specified network.\n" +
            "\n" +
            "You can send funds manually or using QR-code.\n" +
            "\n" +
            "Usually, the bill is confirmed automatically after several confirmations of the network within 10-20 minutes.\n" +
            "\n" +
            "After payment confirmation, the status of your order will be automatically changed."
      },
      {
        "title": "Which wallet or exchange can I use to pay my bill?",
        "text": "You can send cryptocurrency from any wallet or exchange.\n" +
            "\n" +
            "Different wallets and exchanges have different minimum sending amounts and charge different transaction fees.\n" +
            "\n" +
            "When sending, you need to consider the commission that the wallet or exchange will withhold. The exact amount of cryptocurrency specified in the bill must reach our service.\n" +
            "\n" +
            "We recommend using Trust Wallet for fast and accurate payment."
      },
      {
        "title": "What exact amount should I send?",
        "text": "The bill shows the total amount of cryptocurrency that should be sent to the wallet of our service, without taking into account the commission of your wallet or exchange.\n" +
            "\n" +
            "When sending funds you need to check that the correct amount will reach CryptoCloud service after deduction of commission of a wallet or exchange from which you are sending funds.\n" +
            "\n" +
            "Most providers add a commission to the required amount to send, but there are wallets and exchanges where the commission amount is deducted from the total amount to send.\n" +
            "\n" +
            "For example:\n" +
            "\n" +
            "When sending funds from Binance, you specify an amount of 100 USDT. Binance will deduct 1 USDT commission and only 99 USDT will reach the service. In this case, you need to send 101 USDT, so that after deduction of commission the correct amount will reach the service.\n" +
            "\n" +
            "When you send money via Trust Wallet, you specify an amount of 0.001 BTC. Trust Wallet will add a commission of 0.000033 BTC to your amount. Your total amount will be 0,001033 BTC and the service will receive 0,001 BTC.\n" +
            "\n" +
            "If you send a wrong amount, the system will detect your payment and calculate the minimum amount to be paid additionally."
      },
      {
        "title": "Which network to use for sending?",
        "text": "To send Bitcoin, you must use the Bitcoin network.\n" +
            "\n" +
            "To send Litecoin, use the Litecoin network.\n" +
            "\n" +
            "To send USDT you need to use the Tron (TRC20) network.\n" +
            "\n" +
            "At the moment our service doesn't support BEP2 / BEP20 network, Ethereum network"
      },
      {
        "title": "How much time do I have to pay my bill?",
        "text": "The bill is available for payment within 1 hour after being created.\n" +
            "\n" +
            "If you have sent funds and the bill has expired, it will be confirmed as soon as the funds are received.\n" +
            "\n" +
            "In the event that your bill expires and you have not yet paid, we suggest you create a new bill to pay."
      },
      {
        "title": "I sent the correct amount, but the bill is not confirmed.",
        "text": "Bills are confirmed immediately after receiving 1-3 confirmations from the Blockchain network.\n" +
            "\n" +
            "The final confirmation time depends on the Blockchain network load.\n" +
            "\n" +
            "ETH and USDT transactions are completed in less than a minute."
      },
      {
        "title": "What if I sent the wrong amount?",
        "text": "If you send a wrong amount, the system will detect your payment and calculate the minimum amount to be paid additionally.\n" +
            "\n" +
            "In case your wallet or exchange has a limit on sending and the amount of additional payment is less than the limit - you can send a larger amount. Your bill will also be confirmed."
      },
      // {
      //   "title": null,
      //   "text": null
      // },
    ]
  },
  getters: {
  },
  mutations: {
    set_transaction: (state, payload) => {
      state.transaction = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
