<template lang="pug">
v-app
  v-container(fluid v-if="error_1")
    v-row()
      v-col(cols="12").text-center
        span.error_1 {{ error_1_data }}
  v-container(fluid v-else)
    v-row.justify-start.ma-4.flex-nowrap
      v-col.justify-start.mr-2(cols="4")
        v-card.balance(:min-height="min_h" color="#e3e3e3" outlined)
          v-card-title()
            v-icon.mr-1(color="green") mdi-cash-multiple
            span.available Balances
            v-btn(icon color="red" @click="get_data")
              v-icon mdi-refresh

          v-card-text.text-left
            p
            span.total.font-weight-bold Total:&nbsp;
            span.total.font-weight-bold {{balance.toFixed(4)}} LTC
            p
            span.available.font-weight-bold Available:&nbsp;
            span.available.font-weight-bold {{ balance_available.toFixed(4) }} LTC

            p
            span.commission Commission:&nbsp;
            span.commission {{ balance_commission.toFixed(4) }} LTC

            //p
            //span.unaccounted Unaccounted:&nbsp;
            //span.unaccounted {{ balance_unaccounted.toFixed(4) }} LTC
            //
            //p
            //span.commission_unaccounted Unaccounted commission:&nbsp;
            //span.commission_unaccounted {{ balance_commission_unaccounted.toFixed(4) }} LTC
          //span(v-if="!error" class="error_balance") The actual available balance is less than yours! Please contact support immediately!
      v-col(cols="4")
        v-card(:min-height="min_h" )
          v-card-title
            v-icon.mr-1(color="blue" ) mdi-cash-fast
            span.commission Withdrawal
          v-card-text.text-left
            //v-select(v-model="selected_balance" :items="items_balance"
            //  item-value="id" item-text="name" label="Select a balance" persistent-hint :hint="hint_selected_balance" @change="show_balance")
            v-text-field(label="Wallet Address" v-model="address")
            v-text-field(label="Amount" v-model="withdrawal" )
          v-card-actions.justify-center
            v-btn(class="mr-5" @click="(balance === 1)? withdrawal = balance: withdrawal =  (balance).toFixed(4)" color="green" ) Max
            v-btn(color="primary" :disabled="!address || !withdrawal" @click="open_send_money_dialog = true") Withdraw
            //br
            //span(class="success_tr" v-if="tr_hash" ) Tr. hash &nbsp;
            //a(:href="'https://blockchair.com/litecoin/transaction/' + tr_hash") {{ tr_hash }}
            //span(class="error_tr" v-if="tr_error" ) Tr. error &nbsp; {{ tr_error }}
          //span(v-if="!error" class="error_balance") The actual available balance is less than yours! Please contact support immediately!
      v-col(cols="4")
        v-card(color="#e3e3e3" :min-height="min_h" )
          v-card-title
            v-icon.mr-1(color="orange" ) mdi-chart-line
            span.unaccounted Course correction
          v-card-text.text-left
            p
              span.font-weight-bold Current course:&nbsp;
              span.font-weight-bold {{ course }}
            p
              span.font-weight-bold Course correction:&nbsp;
              span.font-weight-bold {{ course_correction }} %
            v-text-field(label="Course correction" v-model="course_correction_input")
          v-card-actions.justify-center
            v-btn(class="mr-5" @click="save_course" color="primary" ) Save
    v-row.ma-auto(v-if="selected_tr.length > 0")
      v-col(cols="6").ma-auto
        v-card(min-height="350")
          v-card-title
            v-icon(color="#8f0262") mdi-clipboard-flow
            span.commission_unaccounted Transaction info
          v-card-text.text-left(v-if="selected_tr.length > 0" ) ID {{ selected_tr[0].id }} | DATE: {{ selected_tr[0].date }}
            p
              span.font-weight-bold Transaction UID:&nbsp;
              br
              code {{ selected_tr[0].uid }}
            p
              span.font-weight-bold Address:&nbsp;
              br
              code {{ selected_tr[0].address }}
            p
              span.font-weight-bold Transaction hash:&nbsp;
              br
              code {{ selected_tr[0].tr_hash }}
            p
              span.font-weight-bold Total:&nbsp;
              span.font-weight-bold {{ (selected_tr[0].exchange_rate).toFixed(4) }} LTC&nbsp;/&nbsp;
              span.font-italic Commission:&nbsp;
              span.font-italic {{ (selected_tr[0].exchange_rate * 0.05).toFixed(4) }} LTC


            p
              span.font-weight-bold Status:&nbsp;
              span(:style="'color:' + status_word(selected_tr[0].status).color" class="status") {{ status_word(selected_tr[0].status).word }}
          //v-card-actions.justify-center(v-if="selected_tr.length > 0")
          //  v-btn.mr-1(:disabled="selected_tr[0].status === 4 || selected_tr[0].status === 1" color="green" @click="dialog_tr_success = true; tr_address=item.address; tr_amount=item.exchange_rate; tr_id=item.id; tr_status=4"  small) Accept
          //  v-btn.mr-1(:disabled="selected_tr[0].status === 1 || selected_tr[0].status ===5 || selected_tr[0].status === 2 || selected_tr[0].status === 3" color="red" small) Reject
          //  v-btn(:disabled="selected_tr[0].status === 4 || selected_tr[0].status === 1" color="orange" @click="dialog_tr_edit = true; tr_address=item.address; tr_amount=item.exchange_rate; tr_id=item.id; tr_status=4"  small) Edit
    v-row(class="ma-auto justify-center" v-if="desserts")
      v-col(cols="12")
        v-tabs(fixed-tabs background-color="black" dark v-model="tab")
          v-tab Income
          v-tab Withdrawal
          v-tab System
        v-tabs-items(v-model="tab")
          v-tab-item
            v-card(flat)
              v-text-field(v-model="search_income"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details)
              v-data-table(:headers="headers_income" :items="desserts.invoice" :search="search_income"
                :footer-props="{'items-per-page-options': [25, 50, 75, 100]}" :items-per-page="50" v-model="selected_tr"
                single-select show-select class="elevation-1")
                template(v-slot:[`item.crypto_currency`]='{ item }')
                  span {{ (item.crypto_currency === 2)? "LTC": "BTC" }}
                template(v-slot:[`item.status`]='{ item }')
                  span(:style="'color:' + status_word(item.status).color" class="status") {{ status_word(item.status).word }}
                template(v-slot:[`item.tr_hash`]='{ item }')
                  a(v-if="item.tr_hash" :href="'https://blockchair.com/litecoin/transaction/' + item.tr_hash" target="_blank") {{ item.tr_hash.slice(0, 5) + '....' }}
                  span(v-else) -
                //template(v-slot:[`item.actions`]='{ item }')
                //  v-tooltip(top color="green" v-if="item.status === 2 || item.status === 3 || item.status === 5")
                //    template(v-slot:activator="{ on, attrs }")
                //      v-btn(icon x-small v-bind="attrs" v-on="on" @click="dialog_tr_success = true; tr_address=item.address; tr_amount=item.exchange_rate; tr_id=item.id; tr_status=4")
                //        v-icon(color="green") mdi-check-bold
                //    span Accept and send to shop
                //  v-tooltip(top color="yellow" v-if="item.status === 2 || item.status === 3 || item.status === 5")
                //    template(v-slot:activator="{ on, attrs }")
                //      v-btn(icon x-small v-bind="attrs" v-on="on" @click="dialog_tr_edit = true; tr_address=item.address; tr_amount=item.exchange_rate; tr_id=item.id; tr_status=4")
                //        v-icon(color="yellow") mdi-credit-card-edit
                //    span Edit amount, accept and send to shop
          v-tab-item
            v-card(flat)
              v-text-field(v-model="search_income"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details)
              v-data-table(:headers="headers_withdraw" :items="desserts.withdraw" :search="search_income"
                :footer-props="{'items-per-page-options': [25, 50, 75, 100]}" :items-per-page="50" v-model="selected_tr" single-select show-select class="elevation-1" )
                template(v-slot:[`item.crypto_currency`]='{ item }')
                  span {{ (item.crypto_currency === 2)? "LTC": "BTC" }}
                template(v-slot:[`item.status`]='{ item }')
                  span(:style="'color:' + status_word(item.status).color" class="status") {{ status_word(item.status).word }}
          v-tab-item
            v-card(flat)
              v-text-field(v-model="search_income"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details)
              v-data-table(:headers="headers_system" :items="desserts.system" :search="search_income" v-model="selected_tr"
                :footer-props="{'items-per-page-options': [25, 50, 75, 100]}"
                :items-per-page="50" single-select show-select class="elevation-1")
                template(v-slot:[`item.crypto_currency`]='{ item }')
                  span {{ (item.crypto_currency === 2)? "LTC": "BTC" }}
                template(v-slot:[`item.status`]='{ item }')
                  span(:style="'color:' + status_word(item.status).color" class="status") {{ status_word(item.status).word }}
    v-divider.mb-5
    v-divider.mb-5
    v-row
      v-col(cols="12")
        v-card()
          v-card-title
            span.commission_unaccounted History filter
          v-card-text
            v-row
              v-col(cols="3")
                v-date-picker(v-model="dates"
                  range)
              v-col(cols="3")
                v-text-field( v-model="dateRangeText"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly)
                v-radio-group(v-model="radio")
                  v-radio(label="All" value="all")
                  v-radio(label="Income" value="income")
                  v-radio(label="Withdrawal" value="withdrawal")
                  v-radio(label="System" value="system")
                v-select(v-model="select" :items="items"  label="Time zone")
              v-col(cols="6")
                v-simple-table
                  template(v-slot:default)
                    thead
                      tr
                        th(class="text-center") Date
                        th(class="text-center") Income(ltc)
                        th(class="text-center") Withdrawal(ltc)
                        th(class="text-center") System(ltc)
                    tbody
                      tr(v-for="(item, index) in desserts2.dates"
                        :key="index")
                        td {{ item }}
                        td {{ desserts2.trans[index] }}
                        td {{ desserts2.withd[index] }}
                        td {{ desserts2.sys[index] }}
    v-row
      v-col(cols="12")
        bar(:chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height")

    v-dialog(width="600" v-model="dialog_tr_success" )
      v-card
        v-card-title Confirm transaction?
        v-card-text Address
          br
          | {{ tr_address }}
          br
          | {{ tr_amount }} LTC
        v-card-actions
          v-btn(color="green" text @click="update_tr") Confirm
          v-btn(color="red" text @click="dialog_tr_success = false") Cancel
    v-dialog(width="600" v-model="dialog_tr_edit" )
      v-card
        v-card-title Edit transaction?
        v-card-text Address
          br
          | {{ tr_address }}
          v-text-field(v-model="tr_amount" label="Amount" )
          | LTC
        v-card-actions
          v-btn(color="green" text @click="update_tr") Confirm
          v-btn(color="red" text @click="dialog_tr_edit = false") Cancel
    v-dialog(width="600" v-model="dialog_tr" )
      v-card
        v-card-title Success
        v-text-field(color="green" class="") Transaction edit success!
        v-card-actions
          v-btn(color="green" text @click="dialog_tr = false") Ok
    v-dialog(width="600" v-model="open_send_money_dialog" )
      v-card
        v-card-title Withdrawal money
        p
        | You will receive {{ (withdrawal * 0.95 - 0.01 ).toFixed(4) }}
        br
        | to address
        br
        | {{ address }}
        br
        | system fee {{ (withdrawal * 0.05 ).toFixed(4) }}
        br
        | litecoin network fee ~0.01
        p
        | If everything is correct and you agree, enter your secret password and click Get
        v-text-field(v-model="secret" label="Secret" type="password" outlined).ma-4
        v-card-actions.justify-center
          v-btn(color="green" text @click="send_money" :disabled="!secret") Get
          v-btn(color="red" text @click="open_send_money_dialog = false") Cancel
    v-dialog(width="600" v-model="send_dialog" )
      v-card
        v-card-title
          span(v-if="tr_hash" style="color: green") Success
          span(v-if="tr_error" style="color: red") Error
        v-card-text
        span(v-if="tr_hash") Money send success!
        span(v-if="tr_error") Money send error!
        br
        span(v-if="tr_hash") Transaction hash
        br
        span(v-if="tr_hash") {{ tr_hash }}
        span(v-if="tr_error") {{ tr_error }}
        v-card-actions
          v-btn(color="green" text @click="send_dialog = false") Ok



</template>

<script>
import axios from "axios";
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import Paid from "@/components/paid";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "dashboard",
  components: {
    Paid,
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      secret: null,
      send_dialog: false,
      open_send_money_dialog: false,
      dates: [],
      radio: "all",
      chartData: {
        labels: [
            '01.09.2022',
            '02.09.2022',
            '03.09.2022',
            '04.09.2022',
            '05.09.2022',
        ],
        datasets: [
          {
            label: 'Income',
            backgroundColor: '#06e07f',
            data: []

          },
          {
            label: 'Withdrawal',
            backgroundColor: '#0157d7',
            data: []

          },
          {
            label: 'Unaccounted',
            backgroundColor: '#ff0000',
            data: []

          },

          {
            label: 'System',
            backgroundColor: '#6c024b',
            data: []

          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      desserts2: [130, 120, 140, 160, 201],

      dialog_tr: false,
      dialog_tr_edit: false,
      tr_address: null,
      tr_amount: null,
      tr_id: null,
      tr_status: null,
      dialog_tr_success: false,
      balance: 0,
      withdrawal: null,
      address: null,
      error: null,
      tabs: ['Income', 'Withdrawal', 'System'],
      tab: null,
      search_income: null,
      headers_income: [
        {
          text: 'ID',
          align: 'start',
          filterable: true,
          value: 'id',
        },
        { text: 'Date Time', value: 'date' },
        { text: 'Total', value: 'exchange_rate' },
        { text: 'Crypto Currency', value: 'crypto_currency' },
        { text: 'Address', value: 'address' },
        {text: 'UID', value: 'uid'},
        {text: 'Transaction hash', value: 'tr_hash'},
        { text: 'Shop order ID', value: 'order_id' },
        { text: 'Status', value: 'status' },
        // { text: '', value: 'actions' , sortable: false, filterable: false},
      ],
      headers_withdraw: [
        {
          text: 'ID',
          align: 'start',
          filterable: true,
          value: 'id',
        },
        { text: 'Date Time', value: 'date' },
        { text: 'Total', value: 'exchange_rate' },
        { text: 'Crypto Currency', value: 'crypto_currency' },
        { text: 'Address', value: 'address' },
        {text: 'UID', value: 'uid'},
        {text: 'Transaction hash', value: 'tr_hash'},
        { text: 'Status', value: 'status' },
      ],
      headers_system: [
        {
          text: 'ID',
          align: 'start',
          filterable: true,
          value: 'id',
        },
        { text: 'Date Time', value: 'date' },
        { text: 'Total', value: 'exchange_rate' },
        { text: 'Crypto Currency', value: 'crypto_currency' },
        {text: 'UID', value: 'uid'},
        {text: 'Transaction hash', value: 'tr_hash'},
        { text: 'Status', value: 'status' },
      ],
      desserts: null,
      tr_hash: null,
      tr_error: null,

      balance_available: 0,
      balance_commission: 0,
      balance_unaccounted: 0,
      balance_commission_unaccounted: 0,

      course_correction_input: null,
      course_correction: 0,
      course: 0,

      selected_balance: null,
      items_balance: [
        {
          id: 1,
          name: 'Available'
        },


      ],
      select_balance: 0,
      hint_selected_balance: null,

      selected_tr: [],
      selected_trw: [],
      selected_trs: [],
      min_h: 350,
      items: [
          'UTC-12:00',
          'UTC-11:00',
          'UTC-10:00',
          'UTC-09:00',
          'UTC-08:00',
          'UTC-07:00',
          'UTC-06:00',
          'UTC-05:00',
          'UTC-04:00',
          'UTC-03:00',
          'UTC-02:00',
          'UTC-01:00',
          'UTC+00:00',
          'UTC+01:00',
          'UTC+02:00',
          'UTC+03:00',
          'UTC+04:00',
          'UTC+05:00',
          'UTC+06:00',
          'UTC+07:00',
          'UTC+08:00',
          'UTC+09:00',
          'UTC+10:00',
          'UTC+11:00',
          'UTC+12:00',
          'UTC+13:00',
      ],
      select: 'UTC+03:00',
      error_1: true,
      error_1_data: null
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  methods: {
    get_data() {
      axios.get(this.$store.state.api_url + 'user/transactions/', {
        headers: {Authorization: "Bearer " + localStorage.getItem('token')}
      }).then(response => {
        console.log(response)
        this.balance = response.data.real_balance
        this.balance_available = response.data.real_balance * 0.95 - 0.01
        this.balance_commission = response.data.real_balance * 0.05
        this.error = response.data.error
        this.desserts = response.data
        this.error_1 = false
      }).catch(reason => {
        console.log(reason.response.status)
        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        } else if (reason.response.status === 500) {
          this.error_1 = true
          this.error_1_data = reason.response.data
        }
      })
    },
    status_word(status) {
      if (status === 1) {
        return {word:"Created", color: "#FFB500FF"}
      }
      else if (status === 2) {
        return {word:"Wait for payment", color: "#6c024b"}
      }
      else if (status === 3) {
        return {word:"Wait for confirmed", color: "#8eff6b"}
      }
      else if (status === 4) {
        return {word:"Success", color: "#45c421"}
      }
      else if (status === 5) {
        return {word:"Canceled", color: "#c90005"}
      }
    },
    send_money() {
      axios.post(this.$store.state.api_url + 'user/send/', {
        address: this.address,
        amount: parseFloat(this.withdrawal),
        secret: this.secret
      }, {headers: {Authorization: "Bearer " + localStorage.getItem('token')}}).then(response => {
        console.log(response)
        this.open_send_money_dialog = false
        this.tr_hash = response.data.result
        this.tr_error = response.data.error
        this.send_dialog = true
        this.withdrawal = null
        this.secret = null
        this.get_data()
      }).catch(reason => {
        console.log(reason)
        if (reason.response.status === 406) {
          this.secret = null
          this.open_send_money_dialog = false
          this.tr_error = 'Too low amount'
          this.send_dialog = true
        }

        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        }
      })
    },
    get_stat() {
      axios.get(this.$store.state.api_url + 'user/statistics/', {
        headers: {Authorization: "Bearer " + localStorage.getItem('token')}
      }).then(response => {
        console.log(response)
        this.chartData.labels = response.data.dates
        this.chartData.datasets[0].data = response.data.trans
        this.chartData.datasets[1].data = response.data.withd
        this.chartData.datasets[2].data = response.data.sys
        this.desserts2 = response.data
      }).catch(reason => {
        console.log(reason.response.status)
        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        }
      })
    },
    update_tr() {
      axios.post(this.$store.state.api_url + 'user/transactions_update/', {
        tr_id: this.tr_id,
        tr_status: this.tr_status,
        tr_value: this.tr_amount
      }, {headers: {Authorization: "Bearer " + localStorage.getItem('token')}}).then(response => {
        console.log(response)
        this.dialog_tr = true
      }).catch(reason => {
        console.log(reason.response.data.error)
        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        }
      })
    },
    get_course() {
      axios.get(this.$store.state.api_url + 'user/get_curs/', {
        headers: {Authorization: "Bearer " + localStorage.getItem('token')}
      }).then(response => {
        console.log(response)
        this.course = response.data.ltc
        this.course_correction = response.data.charge
      }).catch(reason => {
        console.log(reason.response.status)
        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        }
      })
    },
    save_course() {
      axios.post(this.$store.state.api_url + 'user/set_curs/', {
        correction: this.course_correction_input,
      }, {headers: {Authorization: "Bearer " + localStorage.getItem('token')}}).then(response => {
        console.log(response)
        this.course = response.data.ltc
        this.course_correction = response.data.charge
      }).catch(reason => {
        console.log(reason.response.data.error)
        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        }
      })
    },
    show_balance() {
      if (this.selected_balance === 1) {
        this.select_balance = this.balance_available
        this.hint_selected_balance = 'Balance: ' + this.select_balance.toFixed(4) + ' LTC'
      }
      else if (this.selected_balance === 2) {
        this.select_balance = this.balance_unaccounted
        this.hint_selected_balance = 'Balance: ' + this.select_balance.toFixed(4) + ' LTC ' + ' Available: ' + (this.select_balance *0.95).toFixed(4) + ' LTC'
      }
    }
  },
  created() {
    this.check_auth()
    this.get_data()
    this.get_stat()
    this.get_course()
  },
  mounted() {

  },
  watch: {
    withdrawal() {
      if (this.withdrawal > this.balance) {
        this.withdrawal = this.balance
      }
      try {
        this.withdrawal = this.withdrawal.replaceAll(',', '.')
      } catch (error) {
        console.log(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }

    },
    selected_tr() {
      console.log(this.selected_tr)
      // this.min_h = this.$refs.infoBox.clientHeight
      window.scrollTo(0, 400)
    },

  }
}
</script>

<style scoped>
.error_balance {
  color: #d70101;
  font-weight: bold;
}
.status {
  font-weight: bold;
}
.success_tr {
  color: #06e07f;
  font-weight: bold;
}
.error_tr {
  color: #d70101;
  font-weight: bold;
}
.total {
  color: #d70101;
}
.available {
  color: #00881b;
}
.commission {
  color: #0113d7;
}
.unaccounted {
  color: #d79301;
}
.commission_unaccounted {
  color: #6c024b;
}
.error_1 {
  color: #d70101;

}
</style>