import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {colors} from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#17212b',
                secondary: '#808080',
                accent: '#f83f37',
                panel_back: '#ffffff',
                error: colors.red.darken4,
                background: '#c1c1c1',
                bonus: colors.green.darken2

            },
            dark: {
                primary: '#2b5278',
                secondary: '#17212b',
                accent: '#f83f37',
                error: colors.red.darken4,
                panel_back: '#272727',
                background: '#0e1621',
                bonus: colors.red
            },
        },
    },
});
