<template lang="pug">
v-app
  v-container(fluid)
    v-row(class="ma-auto justify-center")
      v-col(cols="4")
        v-card
          v-card-title Login
          v-card-text
            v-text-field(label="Username", v-model="username" :error-messages="error_username" :error="error_u")
            v-text-field(label="Password", type="password" v-model="password" :error-messages="error" :error="error_p")
            //vue-hcaptcha(sitekey="a09e40b9-c5d9-4cd6-b958-a127800487d5" @verify="verify_captcha")
          v-card-actions(class="ma-auto justify-center")
            v-btn(:disabled="!captcha" @click="enter") Enter
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import axios from "axios";

export default {
  name: "login",
  components: { VueHcaptcha },
  data() {
    return {
      captcha: true,
      username: null,
      password: null,
      error: null,
      error_p: false,
      error_u: false,
      error_username: null
    }
  },
  methods: {
    verify_captcha() {
      this.captcha = true
    },
    enter() {
      axios.post(this.$store.state.api_url + 'user/login/', {
        username: this.username,
        password: this.password
      }).then(response => {
        console.log(response)
        localStorage.setItem('token', response.data.token)
        this.$router.push('/dashboard').catch(()=>{});
      }).catch(reason => {
        console.log(reason.response.data.error)
        if (reason.response.data.error === 'password incorrect') {
          this.error = reason.response.data.error
          this.error_p = true
        }
        else {
          this.error_username = reason.response.data.error
          this.error_u = true
        }
      })
    }
  }
}
</script>

<style scoped>

</style>