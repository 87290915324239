<template lang="pug">
  v-container
    v-row.text-center
      v-col(cols='12')
        span To connect your store to our service, please write to us in telegram
        a(href='https://t.me/crypto_turbo_support') @crypto_turbo_support
</template>

<script>
  export default {
    name: 'home',

    data: () => ({

    }),
  }
</script>
