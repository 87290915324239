import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import pay from "@/components/pay";
import payment_confirm from "@/components/payment_confirm";
import paid from "@/components/paid";
import login from "@/components/login";
import dashboard from "@/components/dashboard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pay',
    name: 'pay',
    component: pay,
    props: route => ({ uid: route.query.uid })
  },
  {
    path: '/paid',
    name: 'paid',
    component: paid,
    props: route => ({ uid: route.query.uid })
  },
  {
    path: '/payment_confirm',
    name: 'payment_confirm',
    component: payment_confirm,
    props: route => ({ uid: route.query.uid, crypto_currency: route.query.crypto_currency })
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
