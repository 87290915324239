<template lang="pug">
  .home
    navigation
      home.ma-4
</template>

<script>
// @ is an alias to /src
import navigation from "@/components/navigation";
import home from '@/components/home.vue'

export default {
  name: 'HomeView',
  components: {
    home, navigation
  }
}
</script>
