<template lang="pug">
  v-app
    v-container.ma-auto(fluid='')
      v-row
        v-col
          v-card.mx-auto.my-12.pa-3(:loading='loading' max-width='474')
            template(slot='progress')
              v-progress-linear(color='primary' height='10' indeterminate='')
            v-img(src='@/assets/logo.png')
            v-card-title.text-center.ma-auto.justify-center(v-if='transaction') Payment currency
            v-card-text(v-if='transaction')
              v-row.mx-0.text-center.justify-center(align='center')
                span.text-center.currency Litecoin
              v-row
                v-col.text-left.font-weight-black.subtitle-2.justify-center.align-center.col-lg-7.col-xl-7.col-md-7.col-sm-7.col-xs-6
                  b Total for payment
                v-col.text-left.col-lg-5.col-xl-5.col-md-5.col-sm-5.col-xs-6
                  b(v-if='selection===0') {{transaction.btc}}
                  b(v-else-if='selection===1') {{transaction.ltc}}
                  b(v-else-if='selection===2') {{transaction.value}}
                  v-chip(color='accent' small='') {{crypto_currency}}
                  br
                  b {{transaction.value}} USD
            v-divider.mx-4(v-if='transaction')
            v-card-text(v-if='transaction')
              v-text-field.rounded-lg(v-model='email' type='email' label='ENTER YOUR E-MAIL' placeholder='test@test.com' solo='' :error-messages='email_error_msg' :error='email_error' :rules='emailRules')
              b(v-if='selection === 2 || selection === 0') {{crypto_currency}} payments in develop
            v-card-actions(v-if='transaction')
              v-btn.ma-auto(color='primary lighten-2' @click='reserve' width='100%' :disabled='selection === 2 || selection === 0')
                | Confirm

</template>

<script>
import axios from "axios";

export default {
  name: "pay",
  data: () => ({
    loading: false,
    selection: 1,
    uid: null,
    transaction: null,
    crypto_currency: 'LTC',
    email_error: false,
    email_error_msg: null,
    email: null,
    emailRules: [
      // v => !!v || 'E-mail обязателен',
      v => /.+@.+\..+/.test(v) || 'E-mail must be a valid',
    ],
  }),

  methods: {
    reserve () {
      if (this.email === null) {
        this.email_error_msg = "Email is null"
        this.email_error = true
      }
      else {
        this.$router.push('/payment_confirm?uid='+this.uid+'&crypto_currency='+this.crypto_currency).catch(()=>{});
      }
    },
    get_payment_info() {
      axios.get(this.$store.state.api_url + 'payment/get', {
        params: {uid: this.uid}
      }).then(response => {
        console.log(response)
        this.transaction = response.data
        this.$store.commit('set_transaction', response.data)
        if (this.transaction.status !== 1) {
          if (this.transaction.crypto_currency - 1 === 0) {
            this.crypto_currency = 'BTC'
          } else if (this.transaction.crypto_currency - 1 === 1) {
            this.crypto_currency = 'LTC'
          }
          else if (this.transaction.crypto_currency - 1 === 2) {
            this.crypto_currency = 'USDT'
          }
          this.$router.push('/payment_confirm?uid='+this.uid+'&crypto_currency='+this.crypto_currency).catch(()=>{});
        }
        this.loading = false
      }).catch(reason => {
        console.log(reason.response.status)
      })
    }
  },
  mounted() {
    this.loading = true
    this.uid = this.$route.query.uid
    console.log(this.uid)
  },
  watch: {
    uid() {
      this.get_payment_info()
    },
    selection() {
      if (this.selection === 0) {
        this.crypto_currency = 'BTC'
      } else if (this.selection === 1) {
        this.crypto_currency = 'LTC'
      }
      else if (this.selection === 2) {
        this.crypto_currency = 'USDT'
      }
    }
  }
}
</script>

<style scoped>
.currency {
  color: #f83f37;
  font-size: large;
  font-weight: bold;
}
</style>