<template lang="pug">
  v-app.payment_confirm
    v-container.col-sm-12.col-xs-12(fluid='')
      v-row.ma-auto
        v-col.col-xl-4.col-lg-4.col-md-4.col-sm-4.col-xs-12.ma-auto
          v-img.ma-auto(src='@/assets/logo.png' width='100%' alt='logo')
      v-row.ma-auto
        v-col.col-xl-6.col-lg-6.col-md-6.col-sm-12.col-xs-12.ma-auto
          v-card.ma-auto.pa-5(color='' min-width='100%' min-height='460px')
            v-card-title
              b Details for the bill payment
            v-divider
            v-row
              v-col.text-left.font-weight-black.subtitle-2.justify-center.align-center.col-lg-7.col-xl-7.col-md-7.col-sm-7.col-xs-6
                b Total for payment
                br
                b(v-if='status === 1') Payment will be canceled in:
                countdown(:time='timer * 1000' v-if='status === 1')
                  template(slot-scope='props') {{ props.hours }} hours, {{ props.minutes }} minutes.
              v-col.text-left.col-lg-5.col-xl-5.col-md-5.col-sm-5.col-xs-6.ma-auto
                b(v-if='amount') {{amount}}
                v-chip(color='accent' small='') {{crypto_currency}}
            v-divider.pa-3
            v-card.pa-3.mb-5(color='#f4f5f6' v-if='status === 1')
              v-card-text
                v-text-field(v-model='value' label='Amount' outlined='' readonly='' append-icon='mdi-content-copy' @click="copy_text(value, 'amount')")
                v-text-field(v-model='address' label='WALLET ADDRESS' outlined='' readonly='' append-icon='mdi-content-copy' @click="copy_text(address, 'address')")
            v-btn.text_color.pa-3(v-if='status === 1' color='bonus' width='100%' @click='paid')
              | I have paid
            span.display-1.text_color_accent(v-if='status === 0') Payment has expired.
            br
            span(v-if='status === 0') If you have paid, please contact the operator.
        v-col.col-xl-6.col-lg-6.col-md-6.col-sm-12.col-xs-12.ma-auto
          v-card.ma-auto.pa-6(min-width='100%' min-height='460px')
            v-expansion-panels(inset='')
              v-expansion-panel(v-for='(item,i) in $store.state.faq' :key='i')
                v-expansion-panel-header
                  span.subtitle-2 {{item.title}}
                v-expansion-panel-content
                  p.text-left.font-italic(style='font-size: small' v-for="(abz, index) in item.text.split('')" :key='index')
                    | {{abz}}
</template>

<script>
import axios from "axios";

export default {
  name: "payment_confirm",

  data: () => ({
    loading: false,
    selection: 0,
    uid: null,
    transaction: null,
    crypto_currency: 'BTC',
    address: null,
    value: null,
    timer: 10800,
    amount: null,
    reload_timer: '',
    status: 1
  }),
  methods: {
    get_address() {
      let crypto_currency
      if (this.crypto_currency === 'BTC') {
        crypto_currency = 1
      }
      else if (this.crypto_currency === 'LTC') {
        crypto_currency = 2
      }
      axios.get(this.$store.state.api_url + 'payment/wait_for_payment', {
        params: {uid: this.uid, crypto_currency: crypto_currency}
      }).then(response => {
        console.log(response)
        this.address = response.data.address
        this.value = response.data.value
        this.amount = response.data.value
        this.loading = false
        this.timer = response.data.timer
        if (this.timer === 0) {
          this.status = 0
        }
      }).catch(reason => {
        console.log(reason.response.status)
      })
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
        });
      }
    },
    copy_text(text, type) {
      this.copyToClipboard(text)
          .then(() => {
            if (type === 'amount' && this.value !== "Copied!"){
              let data = this.value
              this.value = "Copied!"
              setTimeout((() => {this.value = data}), 700)
            } else if (type === 'address' && this.address !== "Copied!") {
              let data = this.address
              this.address = "Copied!"
              setTimeout((() => {this.address = data}), 700)
            }
          })
          .catch((r) => console.log(r));
    },
    cancelAutoUpdate() {
      clearInterval(this.reload_timer);
    },
    paid() {
      this.$router.push('/paid?uid='+this.uid).catch(()=>{});
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  mounted() {
    this.loading = true
    this.uid = this.$route.query.uid
    this.crypto_currency = this.$route.query.crypto_currency
    this.get_address()
    this.reload_timer = setInterval(this.get_address, 30000);

  },
}
</script>

<style scoped>
.payment_confirm {
  background-color: #f4f5f6;
}
.text_color {
  color: #f4f5f6;
  font-weight: bold;
}
.text_color_accent {
  color: #f83f37;
}
</style>