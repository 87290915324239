<template lang="pug">
  div
    v-app-bar(elevation='0' color='transparent' width='100%')
      v-row.container(align='center')
        v-col
          router-link.logo_text(to='/')
            .rounded-circle.pa-2.logo_cont
              v-img(width='100%' src='@/assets/logo.png')
        v-spacer
        v-col
          .float-end(v-if='!menu')
            // <router-link active-class="router_link_active" class="router_link font-weight-light" to="/registration">Sign up</router-link>
            router-link.router_link.font-weight-light.ml-5(active-class='router_link_active' to='/login') Login
      v-spacer
      v-btn(icon='' color='background' v-if='menu' @click='drawer = !drawer')
        v-icon mdi-align-horizontal-right
    v-navigation-drawer(v-model='drawer' app='' right='' temporary='')
      v-list(nav='' dense='')
        v-list-item-group(color='primary')
          v-list-item(link='' @click="$router.push('/dashboard').then(r => {})")
            v-icon
            p.ma-0.ml-2 Продукты
          v-list-item(link='' @click='dialog = true')
            v-icon
            p.ma-0.ml-2 Новости
          v-list-item(link='' @click="$router.push('/registration').then(r => {})")
            v-icon
            p.ma-0.ml-2 Регистрация
          v-list-item(link='' @click="$router.push('/login').then(r => {})")
            v-icon
            p.ma-0.ml-2 Войти
    v-dialog(v-model='dialog' max-width='80%')
      v-card
        v-card-title.text-h5
          | Новости
        v-card-actions
          v-spacer
          v-btn(color='primary' text='' @click='dialog = false')
            | Закрыть

</template>

<script>


export default {
  name: "HomeNavigation",
  components: {},
  data() {
    return {
      menu: false,
      drawer: false,
      dialog: false
    }
  },
  mounted() {
    if (this.$store.state.cms === null) {
      this.get_cms()
    }
    setInterval(() => {
      if (window.screen.width <= 760) {
        this.menu = true
      }
    }, 0)
  }
}
</script>

<style scoped lang="scss">
.logo_cont {
  width: 40%;
  box-sizing: border-box;
}
.logo_text {
  text-decoration: none;
  //color: #fff;
}
.router_link {
  //color: white;
  opacity: 0.8;
  position: relative;
  text-decoration: none;

  &:before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform:translateX(-50%);
    background-color: white;
    transition: width 0.3s ease-out;
  }
  &:hover:before {
    width: 100%;
  }
  &_active {
    border-bottom: solid 2px white;
  }
}
@media screen and (max-width: 600px) {
  .container {padding-left: 10px;}
}
</style>