import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueCountdown from '@chenfengyuan/vue-countdown';
import axios from "axios";


Vue.component(VueCountdown.name, VueCountdown);
Vue.config.productionTip = false
Vue.mixin({
  methods: {
    check_auth() {
      axios.get(this.$store.state.api_url + 'user/check/', {
        headers: {Authorization: "Bearer " + localStorage.getItem('token')}
      }).then(response => {
        console.log(response)
        return response.data.user
      }).catch(reason => {
        console.log(reason.response.status)
        if (reason.response.status === 401) {
          localStorage.removeItem('token')
          this.$router.push('/login/').catch(()=>{});
        }
      })
    },
    declOfNum(number, words) {
      return words[(number % 100 > 4 && number % 100 < 20) ?
          2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
    }
  }
})
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
