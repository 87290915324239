<template lang="pug">
  v-app
    v-container.col-sm-12.col-xs-12(fluid='')
      v-row.ma-auto
        v-col.col-xl-4.col-lg-4.col-md-4.col-sm-4.col-xs-12.ma-auto
          v-img.ma-auto(src='@/assets/logo.png' width='20%' alt='logo')
      v-row
        v-col
          span(v-if='uid') Tr No. {{uid}}
          br
          span.status_payment(v-if='!tr_find') Payment not found yet!
          span.status_payment(v-if='tr_find & !complete') Payment found! Wait for confirmation!
          span.status_payment(v-if='complete') Payment complete!
          br
          v-progress-circular.mt-5(v-if='!tr_find' :size='80' :width='7' color='red' indeterminate='') wait
          v-progress-circular.mt-5(v-if='tr_find & !complete' :size='80' :width='7' color='blue' indeterminate='') wait
          v-icon(color='green' v-if='complete' size='100') mdi-check-bold
          br
          span(v-if='complete & success_url') You will be redirected to the store page in 5 seconds
          br
          v-btn(:href='success_url' text='' v-if='complete & success_url') back to store

</template>

<script>
import axios from "axios";

export default {
  name: "paid",
  data: () => ({
    uid: null,
    transaction: null,
    crypto_currency: 'BTC',
    tr_find: false,
    complete: false,
    success_url: null,
    fail_url: null,
    reload_timer: null,
    redirect_timer: null
  }),
  methods: {
    check_payment() {
      axios.get(this.$store.state.api_url + 'payment/check', {
        params: {uid: this.uid}
      }).then(response => {
        console.log(response)
        if (response.data) {
          this.tr_find = response.data.tr_find
          this.complete = response.data.confirmed
          this.success_url = response.data.success_url
          this.fail_url = response.data.fail_url
        }
        this.transaction = response.data
      }).catch(reason => {
        console.log(reason.response.status)
      })
    }
  },
  mounted() {
    this.uid = this.$route.query.uid
    this.check_payment()
    this.reload_timer = setInterval(this.check_payment, 30000);
  },
  watch: {
    complete() {
      if (this.complete && this.success_url) {
        this.redirect_timer = setInterval(window.location.href = this.success_url, 5000);
      }
    }
  }
}
</script>

<style scoped>
.status_payment {
  font-weight: bold;
  font-size: large;
}
</style>